const cbgCompScript = document.getElementById('cbgCompPack');

const scriptSrc = cbgCompScript && cbgCompScript.getAttribute('src');
const initScriptUrl = scriptSrc;
const BASE_URL =
  initScriptUrl?.replace('cbgCompPack.js', '') ||
  'https://compliance-pack.carlsberggroup.com/';

const getEnvAlias = (baseUrl: string): 'dev' | 'stg' | 'prd' => {
  if (typeof baseUrl !== 'string') {
    return 'prd';
  }

  if (baseUrl.includes('localhost')) {
    return 'dev';
  }
  if (baseUrl.includes('dev')) {
    return 'dev';
  }
  if (baseUrl.includes('stg')) {
    return 'stg';
  }
  return 'prd';
};

const APIEnv = {
  dev: 'dev',
  stg: 'stg',
  prd: 'prd'
};

const APIEnvMedia = {
  dev: 'dev',
  stg: 'stg',
  prd: 'live'
};

const envVars = {
  dev: {
    UMBRACO_API_KEY: 'environment-1d5b892f-6b2f-4b37-84f8-7b85d5a50647'
  },
  stg: {
    UMBRACO_API_KEY: 'environment-04ffc80c-a8e0-4695-ba13-8321f1b143f0'
  },
  prd: {
    UMBRACO_API_KEY: 'environment-168d6608-744c-442e-ab20-053cf4c545d6'
  }
};

const envAlias = getEnvAlias(BASE_URL);
const apiEnv = APIEnv[envAlias] as 'dev' | 'stg' | 'prd';
const apiEnvMedia = APIEnvMedia[envAlias];
const isDev = APIEnv[envAlias] === 'dev';
const isStg = APIEnv[envAlias] === 'stg';
const { UMBRACO_API_KEY } = envVars[apiEnv];

export {
  envAlias,
  apiEnv,
  apiEnvMedia,
  isDev,
  isStg,
  BASE_URL,
  UMBRACO_API_KEY
};
