import { getScriptInfo } from 'global-utils/get-attributes';
import { defaultLang } from '../config/defaultLang';

export const getPreferredLang = (availableLangs?: Array<string>) => {
  const { langAttr } = getScriptInfo();
  const htmlTag = document.getElementsByTagName('html')[0];
  const htmlLang = htmlTag && htmlTag.getAttribute('lang');
  const userLang = navigator.language;

  // determine which language value will be returned, by order of preference
  const langPreference = langAttr || htmlLang || userLang;

  // sanitize lang value:: should be two letters and lowercase
  const lang = langPreference.toLowerCase().substring(0, 2);

  /* use case when the utility is used without the availableLangs arg.
   It happens when building the global policies URL and should return
   the preferred language without validating if it's present on available langs */
  if (!availableLangs) {
    return lang;
  }

  // if preferred lang is not available, return the default language instead
  if (!availableLangs || !availableLangs.includes(lang)) {
    return defaultLang;
  }
  return lang;
};
