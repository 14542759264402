const cbgCompScript = document.getElementById('cbgCompPack');

export const getScriptInfo = () => {
  const scriptSrc = cbgCompScript?.getAttribute('src');
  const GTMId = cbgCompScript?.getAttribute('data-gtm-id');
  const GTMId4 = cbgCompScript?.getAttribute('data-gtm-id-ga4');
  const langAttr = cbgCompScript?.getAttribute('data-culture');
  const env = cbgCompScript?.getAttribute('data-env');
  const showAgeGateway: boolean =
    cbgCompScript?.getAttribute('data-verify-age') === 'true';
  const checkCookies: boolean =
    cbgCompScript?.getAttribute('data-set-cookies') !== 'false';

  return {
    GTMId,
    GTMId4,
    langAttr,
    scriptSrc,
    showAgeGateway,
    checkCookies,
    env
  };
};

export const getProjectInfo = () => {
  const projectId = cbgCompScript?.getAttribute('data-prj-id');
  const prjMarket = cbgCompScript?.getAttribute('data-market');
  const entityNameAttr = cbgCompScript?.getAttribute('data-entity-name');
  const formalEntityAttr = cbgCompScript?.getAttribute('data-formal-entity');

  return { projectId, prjMarket, entityNameAttr, formalEntityAttr };
};

export const getCConsentInfo = () => {
  const cbgCCScript = document.getElementById('cbgCConsent');
  const userContinent = cbgCCScript?.getAttribute('continent');
  const userLocation = cbgCCScript?.getAttribute('location');
  return { userContinent, userLocation };
};
