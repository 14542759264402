import { POLICIES_BASE_URL } from '../config/api';

const cbgCompScript = document.getElementById('cbgCompPack');

export const getPoliciesUrl = (
  lang: string,
  prjMarket: string,
  prjId: string
) => {
  let policiesUrl = null;
  let cookiesUrl = null;

  const policiesPageAttr = cbgCompScript?.getAttribute('data-policy-page');
  const cookiesPAgeAttr = cbgCompScript?.getAttribute('data-cookies-page');

  if (
    (!prjId && (!policiesPageAttr || !cookiesPAgeAttr)) ||
    (!prjMarket && (!policiesPageAttr || !cookiesPAgeAttr))
  ) {
    console.error(`cbgCConsent:the plugin was unable to determine the cookies or privacy notification URL. 
    {{data-prj-id}} or {{data-market}} attributes are missing on the cbgCompPack script. Please fix this issue. 
    Default policies link will be presented instead
    `);
  }

  if (policiesPageAttr) {
    policiesUrl = policiesPageAttr;
  }

  if (cookiesPAgeAttr) {
    cookiesUrl = cookiesPAgeAttr;
  }

  if (!policiesPageAttr && prjId && prjMarket) {
    policiesUrl = `${POLICIES_BASE_URL}/externals/${lang}-${prjMarket}?id=${prjId}`;
  }

  if (!cookiesPAgeAttr && prjId && prjMarket) {
    cookiesUrl = `${POLICIES_BASE_URL}/externals/${lang}-${prjMarket}/cookies?id=${prjId}`;
  }

  return {
    policiesUrl,
    cookiesUrl
  };
};
