type ReadyCallback = () => void;

export function ready(callback: ReadyCallback): void {
  // in case the document is already rendered
  if (document.readyState !== 'loading') callback();
  // modern browsers
  else if (document.addEventListener)
    document.addEventListener('DOMContentLoaded', callback);
  // IE <= 8
  else
    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') callback();
    });
}
