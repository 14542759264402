const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const setAgeParam = urlParams.get('cbgC');

const ageGateParam = urlParams.get('cbgAge');

const shouldSetAgeCookie = ageGateParam && setAgeParam === 'true';
const noCookiesParam = urlParams.get('cbgNoCookies');

const isCookiesEnabled = () => {
  if (navigator.cookieEnabled) {
    return true;
  }
  return false;
};

const getCookieValue = (cookieName: string) => {
  let cookieValue;

  const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));

  if (match) {
    const cookieValueIndex = 2;
    cookieValue = match[cookieValueIndex];
    cookieValue = cookieValue.split(',');
  }

  return cookieValue;
};

const getDomain = (url: string) => {
  // Find the position of the third slash to locate the end of the domain
  const thirdSlashIndex = url.indexOf('/', url.indexOf('//') + 2);

  // Extract the domain portion from the URL
  const domainPortion = url.substring(
    url.indexOf('//') + 2,
    thirdSlashIndex !== -1 ? thirdSlashIndex : undefined
  );
  // evaluates to hostname with starting `www.` removed
  const domain =
    domainPortion.indexOf('www.') === 0
      ? domainPortion.replace('www.', '')
      : url;

  // these are TLD cases that have on or more dots on its' composition, add any known case to this list
  const specialTLDcases = [
    '.co.uk',
    '.com.hk',
    '.com.sg',
    '.com.my',
    '.com.mm'
  ];

  // Split the host string by dots (.)
  const parts = domain.split('.');

  // Check if the domain has a subdomain (at least three parts)
  if (parts.length >= 3) {
    if (
      specialTLDcases.includes(
        `.${parts[parts.length - 2]}.${parts[parts.length - 1]}`
      )
    ) {
      // Return the last three parts as the main domain (e.g., "subdomain.domain.co.uk")
      return parts.slice(-3).join('.');
    }
    // Return the last two parts as the main domain (e.g., "example.com")
    return parts.slice(-2).join('.');
  }
  // Return the entire host if there's no subdomain (e.g., "localhost" or "example.com")
  return domain;
};

const setBrowserCookie = (
  cookieName: string,
  value: string | number | boolean | { [key: string]: boolean },
  session?: boolean
) => {
  const now = new Date();
  const { host } = window.location;

  const expiresOn = new Date(now);
  expiresOn.setDate(now.getDate() + 365);
  localStorage.setItem(`${cookieName}_created_date`, now.toString());

  const expireDate = session ? 0 : expiresOn.toUTCString();
  // evaluates to hostname with starting `www.` removed
  const domain = getDomain(host);

  // Domain = 'localhost' does not work with document.cookie method,
  // in this case do not set the Domain attr.
  if (host.split('.').length === 1) {
    // no "." in a domain - it's localhost or something similar
    document.cookie = `${cookieName}=${value}; expires=${expireDate}; path=/; SameSite=Lax;`;
    return;
  }

  document.cookie = `${cookieName}=${value}; expires=${expireDate}; path=/; SameSite=Lax; domain=${domain};`;
};

const deleteCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; SameSite=Lax;`;
};

const checkConsentGiven = () => {
  const consentValues = getCookieValue('cbg_ccp');
  const cbgCookieValues =
    consentValues &&
    consentValues.map((consent) => ({
      type: consent,
      value: true
    }));

  return {
    consentValues,
    noConsentIsGiven: !consentValues,
    cbgCookieValues
  };
};

// if there is a cbg_ccp cookie set on the browser the user already interacted with the cookie form
const userGaveConsent = () => {
  const consentValues = getCookieValue('cbg_ccp');
  return !!consentValues;
};

export {
  ageGateParam,
  shouldSetAgeCookie,
  noCookiesParam,
  isCookiesEnabled,
  getCookieValue,
  setBrowserCookie,
  deleteCookie,
  checkConsentGiven,
  userGaveConsent,
  getDomain
};
