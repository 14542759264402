import { getScriptInfo } from 'global-utils/get-attributes';
import { dataLayer } from './globals';

const { GTMId, GTMId4 } = getScriptInfo();

const loadGTM = (w: any, d: any, s: any, l: any, i: any) => {
  const array = w;
  array[l] = array[l] || [];
  array[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const f = d.getElementsByTagName(s)[0];
  const script = document.createElement('script');
  const dl = l !== 'dataLayer' ? `&l=${l}` : '';
  const encodedId = encodeURIComponent(i);
  const encodedDl = encodeURIComponent(dl);
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${encodedId}${encodedDl}`;
  f.parentNode.insertBefore(script, f);
};

const getGTMContainer = () => {
  const GTMObj = window.google_tag_manager;
  const isOnPage = !!GTMObj;
  const id = isOnPage
    ? Object.keys(GTMObj).filter((elem) => elem.startsWith('GTM'))
    : undefined;

  return {
    isOnPage,
    currentGTMId: id ? id.toString() : undefined
  };
};

const { isOnPage, currentGTMId } = getGTMContainer();

const initGTM = () => {
  if (!GTMId) {
    console.error(
      'cbgCompPack:: could not get GTM Id, please pass you GTM container ID as an attribute to the cbgCompPack script:: data-gtm-id=[ID]'
    );
  }

  if (isOnPage) {
    if (currentGTMId !== GTMId && currentGTMId !== GTMId4) {
      console.error(
        'cbgCompPack:: there is GTM container not handled by the Carlsberg compliance plugin on the page, please remove it'
      );

      console.warn(
        'cbgCompPack:: any Google tag manager script should be deployed by the Carlsberg compliance plugin only'
      );
    }
  }

  // inject GTM container on page
  if (GTMId) {
    loadGTM(window, document, 'script', 'dataLayer', GTMId);
  }

  if (GTMId4) {
    loadGTM(window, document, 'script', 'dataLayer', GTMId4);
  }
};

const sendSubmitEvent = () => {
  dataLayer.push({ event: 'preferences_submitted' });
};

export { initGTM, sendSubmitEvent, loadGTM, getGTMContainer };
