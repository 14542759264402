import { fetchWithTimeout } from 'global-utils/api';
import { GEO_API_URL } from '../config/api';

async function fetchGeoLocation() {
  let result;
  try {
    result = await fetchWithTimeout(GEO_API_URL);
  } catch (err) {
    /*  const loadingScreen = document.getElementById('cbg-loading-screen');
    loadingScreen?.remove(); */
    console.error(err);
  }

  return result;
}

export default fetchGeoLocation;
