declare global {
  interface Window {
    dataLayer: Array<{ [key: string]: any }>;
    // eslint-disable-next-line camelcase
    com_carlsberg_compliance_pack: { [key: string]: any };
    // eslint-disable-next-line camelcase
    google_tag_manager: any;
    GoogleAnalyticsObject: string;
    gaName: string;
    ga: string;
    addConsentListener: (func: () => void) => void;
    consentListeners?: ((consent: string[]) => void)[];
  }
}

// dataLayer stores relevant GTM (Google Tag Manager) events
window.dataLayer = window.dataLayer || [];
window.com_carlsberg_compliance_pack =
  window.com_carlsberg_compliance_pack || {};

export const globalScope = window.com_carlsberg_compliance_pack;

// Array of callbacks to be executed when consent changes
globalScope.consentListeners = globalScope.consentListeners || [];
globalScope.consentDefaultListeners = globalScope.consentDefaultListeners || [];

const { consentListeners, consentDefaultListeners } = globalScope;

const addConsentListener = (callback: (consent: string[]) => void) => {
  consentListeners.push(callback);
};

const addGetConsentListener = (callback: (userRegion: string[]) => void) => {
  // get default GTM consent settings
  consentDefaultListeners.push(callback);
};

const getDefaultConsent = (userRegion: string) => {};

globalScope.addConsentListener =
  globalScope.addConsentListener || addConsentListener;
globalScope.addGetConsentListener =
  globalScope.addGetConsentListener || addGetConsentListener;

export const { dataLayer } = window;

/**
 * Called when user grants/denies consent.
 * @param {Object} consent Object containing user consent settings.
 */
export const onConsentChange = (consent: string[]) => {
  consentListeners.forEach((callback: (consent: string[]) => void) => {
    callback(consent);
  });
};

const waitForConsentDefaultListeners = (): Promise<void> =>
  new Promise((resolve) => {
    const interval = setInterval(() => {
      if (consentDefaultListeners.length > 0) {
        clearInterval(interval);
        resolve();
      }
    }, 50); // Check every 50 milliseconds, adjust as needed
  });

export const getGTMDefaultConsent = async (userRegion: string) => {
  await waitForConsentDefaultListeners();
  let defaultConsent;
  consentDefaultListeners.forEach(
    (callback: (userRegion: string) => { [key: string]: string }) => {
      const GTMConsent = callback(userRegion);
      defaultConsent = GTMConsent;
    }
  );
  return defaultConsent;
};
